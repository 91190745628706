/* eslint-disable react/prop-types */
import React from 'react';

import { Button, Cross, Icon } from '@xceedsrl/jukebox';

function CloseButton({ color, ...props }) {
  return (
    <Button variant="round" boxShadow={0} backgroundColor="transparent" p={0} {...props}>
      <Icon color={color} isPointer>
        <Cross />
      </Icon>
    </Button>
  );
}

CloseButton.defaultProps = {
  fontSize: 20,
};

export default React.memo(CloseButton);
